import React from 'react';
import '../App.css';

import bigShowFlierImage from "../images/tour flier updated.jpg";

export default class CozyboyShowFlier extends React.Component {
  render() {
    return (
      <div id="CozyboyShowFlier" className="cozyboyShowFlierComponent">
        <img className="bigShowFlierImage" src={bigShowFlierImage} />
      </div>
    );
  }
}
