import React from 'react';
import '../App.css';

import Subtitle from "./Subtitle"

import youDeserveBetterImage from "../images/you deserve better.jpg";
import iWontHurtYouImage from "../images/i wont hurt you.jpg";
import feelingDownImage from "../images/feeling down (with).jpg";

export default class CozyboyMusic extends React.Component {
  constructor(props) {
    super(props)

    this.youDeserveBetterUrl = "https://open.spotify.com/track/3oknkOxQ2te2qV2bECmun7?si=88886c98ca3b4968";
    this.iWontHurtYouUrl = "https://open.spotify.com/track/2pWXYWoOzRSOpB88HjpSlj?si=9043ad2453de4df0";
    this.feelingDownUrl = "https://open.spotify.com/track/4l5L5uLTgRTd9jF6ZjCObP?si=4fb1d86600b343c4";
  }
  render() {
    return (
      <div id="cozyboyMusic" className="cozyboyMusic">
        <div className="musicTitle">MUSIC</div>
        <Subtitle
          text="for you to listen"
        />
        <div className="projectsContainer" style={{marginBottom: 10}}>
          <div className="projectContainer">
            <a href={this.youDeserveBetterUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={youDeserveBetterImage} alt="modern dating" /></a>
            <div className="projectName">YOU DESERVE BETTER<br/>(I WANT YOU)</div>
          </div>

          <div className="projectContainer">
            <a href={this.iWontHurtYouUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={iWontHurtYouImage} alt="a bedroom story" /></a>
            <div className="projectName">I WONT HURT YOU</div>
          </div>

          <div className="projectContainer">
            <a href={this.feelingDownUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={feelingDownImage} alt="disposed" /></a>
            <div className="projectName">FEELING DOWN</div>
          </div>

        </div>
        <iframe
          className="spotifyPlaylist1"
          src="https://open.spotify.com/embed/album/6FIig8oMG4LfjtXRs7EJcB"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
          title="spotify iframe"
        ></iframe>
      </div>
    );
  }
}
