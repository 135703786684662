import React from 'react';
import '../App.css';

export default class CozyboyHome extends React.Component {

  render() {

    return (
      <div id="cozyboyHome" className="cozyboyHome">
        <div className="splashSection">

          <div className="headerTitleContainer">
            <span className="headerTitle">
              CØZYBØY
            </span>
            <div className="headerSubtitle">
              MODERN DATING AND HEARTBREAK
            </div>
          </div>

        </div>
      </div>
    );
  }
}
