import React from 'react';
import '../App.css';
import merch1 from "../images/merch-1.jpg";

import Subtitle from "./Subtitle"

export default class CozyboyMerch extends React.Component {

  render() {
    return (
      <div id="cozyboyMerch" className="cozyboyMerch">
        <div className="merchTitle">CØZYBØY CØLLECTIØN</div>
        <Subtitle
          text="for you to have"
        />
        <a href="https://cozyboycollection.com" target="_blank" rel="noreferrer">
          <img className="merchImage" src={merch1} alt="merch-1.jpg" />
        </a>
        <div className="bottomBorderBlack"></div>
      </div>
    );
  }
}
