import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";

import CozyboyHome from './components/CozyboyHome';
import CozyboyMusic from './components/CozyboyMusic';
import CozyboyVideos from './components/CozyboyVideos';
import CozyboyMerch from './components/CozyboyMerch';
import CozyboyShowFlier from './components/CozyboyShowFlier';
import CozyboyTour from './components/CozyboyTour';
import CozyboyShows from './components/CozyboyShows';
import CozyboyProjects from './components/CozyboyProjects';
import CozyboyGallery from './components/CozyboyGallery';

import logo1 from './images/logo-1.jpg'

import appleIcon from './images/icons/apple-128.png'
import facebookIcon from './images/icons/facebook-3-128.png'
import instagramIcon from './images/icons/instagram-128.png'
import snapchatIcon from './images/icons/snapchat-2-128.png'
import soundcloudIcon from './images/icons/soundcloud-128.png'
import spotifyIcon from './images/icons/spotify-128.png'
import tikTokIcon from './images/icons/tiktok-128.png'
import twitchIcon from './images/icons/twitch-tv-128.png'
import twitterIcon from './images/icons/twitter-128.png'
import youtubeIcon from './images/icons/youtube-128.png'

// import heartbrokeAudio from './audio/heartbroke.mp3'

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmail: false,
      isLogo: false,
      // play: false,
    }

    // this.audio = new Audio(heartbrokeAudio);
  }

  componentDidMount() {
      // this.audio.addEventListener('ended', () => {
      //     this.setState({ play: false })
      // });
  }

  componentWillUnmount() {
      // this.audio.removeEventListener('ended', () => this.setState({ play: false }));
  }

  playAudio() {
      this.audio.play();
      this.setState({ play: true })
  }

  goToPage(page) {
    window.scrollTo({
      top: document.getElementById(page).offsetTop - 60,
      behavior: 'smooth'
    });
  }

  newTab(link) {
      window.open(link, "_blank");
  }

  toggleEmail() {
    this.setState({isEmail: !this.state.isEmail});
  }

  toggleLogo() {
    this.setState({isLogo: true});
  }

  closeLogo() {
    this.setState({isLogo: false});
  }

  renderLogo() {
    return (
      [
        <div key="86549" className="closeButton" onClick={this.closeLogo.bind(this)}>
          ×
        </div>,
        <div key="86550" className="logoRedBackground">
          <div className="logoImage"></div>
        </div>
      ]
    )
  }

  render() {
    return (
      <div className="App">
        {this.state.isLogo ? this.renderLogo() : null}
        <Router>
          <div className="fixedHeader">
            <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyHome")}
            >
              CØZYBØY
            </div>
            {/* <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyTour")}
            >
              TØUR
            </div>
            <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyShows")}
            >
              SHØWS
            </div> */}
            <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyMusic")}
            >
              MUSIC
            </div>
            <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyVideos")}
            >
              VIDEØS
            </div>
            <div
              className="headerItem"
              onClick={this.goToPage.bind(this, "cozyboyMerch")}
            >
              MERCH
            </div>
            <div
              className="headerItem"
              onClick={this.newTab.bind(this, "https://bit.ly/cozyboy-epk")}
            >
              EPK
            </div>
            <div
              className="headerItem"
              onClick={this.toggleEmail.bind(this)}
            >
              CØNTACT
            </div>
            {this.state.isEmail ?
              <div className="headerEmail">
                <div style={{marginTop: -2}}>cozyboymusic.management@gmail.com</div>
              </div>
              :
              null
            }

            <div className="iconsRightContainer">
              <a href="https://instagram.com/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={instagramIcon} alt="instagram icon" /></a>
              <a href="https://twitter.com/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={twitterIcon} alt="twitter icon" /></a>
              <a href="https://youtube.com/c/cozyboy" target="_blank" rel="noreferrer"><img className="socialIcon" src={youtubeIcon} alt="youtube icon" /></a>
              <a href="https://open.spotify.com/artist/3sWtaNsmsCx0TFaQkP8QAm?si=Y6OGnpc8S-WvQZNG7j1jBA" target="_blank" rel="noreferrer"><img className="socialIcon" src={spotifyIcon} alt="social icon" /></a>
              <a href="https://music.apple.com/us/artist/c%C3%B8zyb%C3%B8y/1255674432" target="_blank" rel="noreferrer"><img className="socialIcon" src={appleIcon} alt="apple icon" /></a>
              <a href="https://soundcloud.com/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={soundcloudIcon} alt="soundcloud icon" /></a>
              <a href="https://tiktok.com/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={tikTokIcon} alt="tiktok icon" /></a>
              <a href="https://twitch.tv/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={twitchIcon} alt="twitch icon" /></a>
              <a href="https://www.snapchat.com/add/cozyconner" target="_blank" rel="noreferrer"><img className="socialIcon" src={snapchatIcon} alt="snapchat icon" /></a>
              <a href="https://facebook.com/cozyboycries" target="_blank" rel="noreferrer"><img className="socialIcon" src={facebookIcon} alt="facebook icon" /></a>

              <img className="logo1" src={logo1} alt="logo-1.jpg" onClick={this.toggleLogo.bind(this)} />
            </div>
          </div>



          <Switch>
            <Route path="/">
              <CozyboyHome />
              <iframe
                scrolling="no"
                frameBorder="no"
                // allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/985672315&color=%23ff00ff&inverse=true&auto_play=true&show_user=true"
                style={{
                  width: "100%",
                  height: 50,
                  position: "fixed",
                  bottom: -3,
                  left: 0,
                  zIndex: 1000,
                  padding: 15,
                  boxSizing: "border-box",
                  backgroundColor: "rgba(0,0,0,.8)",
                }}
              ></iframe>

              {/* <iframe src="https://open.spotify.com/embed/track/5qaUgmWGFWWdR71R1GpZH5" width="100%" height="80" frameBorder="0" allowTransparency="true" allow="encrypted-media" autoplay="true"></iframe> */}

              {/* <CozyboyShowFlier /> */}
              {/* <CozyboyTour /> */}
              <CozyboyShows />
              <CozyboyMusic />
              <CozyboyVideos />
              <CozyboyMerch />
              <CozyboyProjects />
              <CozyboyGallery />

            </Route>
            <Route path="/music">
              <CozyboyMusic />
            </Route>
            <Route path="/videos">
              <CozyboyVideos />
            </Route>
          </Switch>
        </Router>

        <div className="footer">
          MADE BY&nbsp;<span className="footerCozyboy">CØZYBØY</span>&nbsp;WITH LØVE 💙
        </div>
      </div>
    );
  }
}
