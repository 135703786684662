import React from 'react';
import '../App.css';

export default class CozyboyTour extends React.Component {
  constructor(props) {
    super(props)
    this.showInfo = [
      {
        date: "Fri 1 Apr",
        city: "Houston, TX",
        venue: "Super Happy Fun Land",
        link: "https://www.eventbrite.com/e/i-promisei-love-you-tour-cozyboythomas-reid-arkaeo-tickets-237621601607",
      },
      {
        date: "Sat 2 Apr",
        city: "Fort Worth, TX",
        venue: "The Ridglea THEATER",
        link: "https://www.eventbrite.com/e/thomas-reid-cozyboy-arkaeo-penny-bored-matchstick-ghost-and-new-heros-tickets-241464836827",
      },
      {
        date: "Sun 3 Apr",
        city: "Atlanta, GA",
        venue: "Smiths Olde Bar",
        link: "https://www.freshtix.com/events/cozyboy--thomas--reid--arkaeo",
      },

      // {
      //   date: "Thu 7 Apr",
      //   city: "Raleigh, NC",
      //   venue: "Pour House",
      //   link: "https://www.eventbrite.com/e/cozyboy-thomas-reid-w-arkaeo-paycheck-brimhood-big-john-tickets-244061032117",
      // },
      // {
      //   date: "Fri 8 Apr",
      //   city: "Long Island, NY",
      //   venue: "Shakers Pub",
      //   link: "https://www.eventbrite.com/e/238656868117",
      // },
      // {
      //   date: "Sat 9 Apr",
      //   city: "New Brunswick, NJ",
      //   venue: "Ghoul Lagoon",
      //   link: "https://www.eventbrite.com/e/242397897637",
      // },
      // {
      //   date: "Sun 10 Apr",
      //   city: "Allentown, PA",
      //   venue: "Sportmens Cafe",
      //   link: "https://www.eventbrite.com/e/237892170887",
      // },

      {
        date: "Fri 15 Apr",
        city: "Nashville, TN",
        venue: "Ponobes",
        link: "https://www.eventbrite.com/e/237674259107",
      },
      {
        date: "Sat 16 Apr",
        city: "Indianapolis, IN",
        venue: "Emerson Theater",
        link: "https://www.eventbrite.com/e/237674259107",
      },
      {
        date: "Sun 17 Apr",
        city: "Chicago, IL",
        venue: "Foundation Room",
        link: "https://www.ticketmaster.com/event/04005C4AEA0435DF",
      },

      {
        date: "Fri 22 Apr",
        city: "Seattle, WA",
        venue: "Funhouse",
        link: "https://wl.seetickets.us/event/Cozyboy-ThomasReid/462111?afflky=ElCorazon",
      },
      // {
      //   date: "Sat 23 Apr",
      //   city: "Portland, OR",
      //   venue: "TBA",
      //   link: "",
      // },

      // {
      //   date: "Sat 30 Apr",
      //   city: "Anaheim, CA",
      //   venue: "Chain Reaction",
      //   link: "",
      // },
      {
        date: "Sun 1 May",
        city: "San Diego, CA",
        venue: "Kensington Club",
        // link: "DOOR",
      },
    ];
  }

  newTab(link) {
    window.open(link, "_blank");
  }

  renderTicketLink(show) {
    if(!show.link) {
      return <button className="ticketButton" onClick={() => {}}>DOOR SALES</button>
    }

    if(!show.link.length) {
      return <button className="ticketButton">TICKETS PENDING</button>
    }

    if(show.link.length) {
      return <button className="ticketButton" onClick={this.newTab.bind(this, show.link)}>TICKETS</button>
    }
  }

  render() {
    return (
      <div id="cozyboyTour" className="cozyboyTour">
        {/* <div className="showTitle">
          SHØWS CØMING SØØN
        </div> */}
        <div className="tourTitle">"I PROMISE, I LOVE YOU TOUR"</div>
        <div className="tourSubtitleSmall">WITH</div>
        <div className="tourSubtitle">CØZYBØY</div>
        <div className="tourSubtitle">THOMAS REID</div>
        <div className="tourSubtitle">ARKAEO</div>

        <div className="showOverlayInner">
          {this.showInfo.map((show, showIndex) => {
            return (
              <div className="showInfo">
                <div className="showDate">
                  {this.renderTicketLink(show)}

                  <div className="showDateText">{show.date}</div>
                </div>
                <div className="showCity">
                  {show.city}
                </div>
                <div className="showVenue">
                  {show.venue}
                </div>
              </div>
            )
          })}
        </div>

        {/* <div className="showOverlay"></div> */}
      </div>
    );
  }
}
