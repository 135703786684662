import React from 'react';
import '../App.css';

import Subtitle from "./Subtitle"

import modernDatingProjectImage from "../images/modern-dating.jpg";
import aBedroomStoryImage from "../images/a-bedroom-story.jpg";
import disposedImage from "../images/disposed.jpg";
import comeUndoneImage from "../images/come-undone.jpg";

export default class CozyboyProjects extends React.Component {
  constructor(props) {
    super(props)

    this.modernDatingUrl = "https://open.spotify.com/album/6FIig8oMG4LfjtXRs7EJcB?si=K5N5b470R32XF7FYUOyg2w";
    this.aBedroomStoryUrl = "https://open.spotify.com/album/1CYbQYkBpw2tOoVjltVEJW?si=fATBRFhoRHOx69Oo9JSVQg";
    this.disposedUrl = "https://open.spotify.com/album/4GcU0H4MQvngifrfd6M7kb?si=iTKr_cBIS8ec3CIZFXRNGQ";
    this.comeUndoneUrl = "https://open.spotify.com/album/2tkcttCqcy7CLqDUl32UzB?si=MyMqyjtJS0iDTwFsxfCFNQ";
  }
  render() {
    return (
      <div id="cozyboyProjects" className="cozyboyProjects">
        <div className="projectsTitle">PRØJECTS</div>
        <Subtitle
          text="for you to think"
        />

        <div className="projectsContainer">
          <div className="projectContainer">
            <a href={this.modernDatingUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={modernDatingProjectImage} alt="modern dating" /></a>
            <div className="projectName">MODERN DATING <br />AND HEARTBREAK</div>
          </div>

          <div className="projectContainer">
            <a href={this.aBedroomStoryUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={aBedroomStoryImage} alt="a bedroom story" /></a>
            <div className="projectName">A BEDRØØM STØRY</div>
          </div>

          <div className="projectContainer">
            <a href={this.disposedUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={disposedImage} alt="disposed" /></a>
            <div className="projectName">DISPØSED</div>
          </div>

          <div className="projectContainer">
            <a href={this.comeUndoneUrl} target="_blank" rel="noreferrer"><img className="projectImage" src={comeUndoneImage} alt="come undone" /></a>
            <div className="projectName">CØME UNDØNE</div>
          </div>

        </div>
        <div className="bottomBorderBlack"></div>
      </div>
    );
  }
}
