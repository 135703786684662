import React from 'react';
import '../App.css';

export default class Subtitle extends React.Component {

  render() {
    const pr = this.props;

    return (
      <div className="subtitleComponent">
        <div className="subtitleLine" style={{backgroundColor: `${pr.color ? pr.color : "black"}`}}></div>
        <div className="subtitle" style={{color: `${pr.color ? pr.color : "black"}`}}>
          {pr.text}
        </div>
        <div className="subtitleLine" style={{backgroundColor: `${pr.color ? pr.color : "black"}`}}></div>
      </div>
    );
  }
}
