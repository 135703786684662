import React from 'react';
import '../App.css';

import showFlier1 from "../images/show fliers/02-12-22 cloudkill.jpeg";
import showFlier2 from "../images/show fliers/02-25-22 steven moses.JPEG";
import showFlier3 from "../images/show fliers/03-11-22 super sad showcase.JPG";

import Subtitle from "./Subtitle"

export default class CozyboyShows extends React.Component {
  constructor(props) {
    super(props)
    this.showInfo = [
      {
        date: "Fri 26 Mar PROMO CODE \"COZYBOY\"",
        city: "Santa Monica, CA",
        venue: "SNO Arts Festival",
        link: "https://www.snoculture.com/snoevents",
      },
    ];

    this.showFliers = [
      // {
      //   image: showFlier2,
      //   link: "https://www.eventbrite.com/e/238495244697",
      //   title: "Steven Moses",
      // },
    ];
  }

  newTab(link) {
    window.open(link, "_blank");
  }

  render() {
    return (
      <div id="cozyboyShows" className="cozyboyShows">
        {/* <div className="showTitle">
          SHØWS CØMING SØØN
        </div> */}
        <div className="videosTitle">SHØWS</div>
        <Subtitle
          text="click show flier or ticket button for tickets"
          color="white"
        />

        <div className="showFlierImagesContainer">
          {this.showFliers.map((showFlier, showFlierIndex) => {
            return (
              <img
                className="showFlierImage"
                key={`showFlierIndex-${showFlierIndex}`}
                src={showFlier.image}
                onClick={this.newTab.bind(this, showFlier.link)}
              />
            )
          })}
        </div>

        <div className="showOverlayInner">
          {this.showInfo.map((show, showIndex) => {
            return (
              <div className="showInfo">
                <div className="showDate">
                  {show.link.length ?
                    <button className="ticketButton" onClick={this.newTab.bind(this, show.link)}>TICKETS</button>
                    :
                    <button className="ticketButton">TICKETS PENDING</button>
                  }

                  <div className="showDateText">{show.date}</div>
                </div>
                <div className="showCity">
                  {show.city}
                </div>
                <div className="showVenue">
                  {show.venue}
                </div>
              </div>
            )
          })}
        </div>

        {/* <div className="showOverlay"></div> */}
      </div>
    );
  }
}
