import React from 'react';
import '../App.css';

import Subtitle from "./Subtitle"

import photo0 from "../images/cozy-408.jpg";
import photo1 from "../images/cozy-407.jpg";
import photo2 from "../images/cozy-409.jpg";
import photo3 from "../images/cozy-epk-2.jpg";

export default class CozyboyGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photos: [
        {
          isSelected: true,
          url: photo0,
        },
        {
          isSelected: false,
          url: photo1,
        },
        {
          isSelected: false,
          url: photo2,
        },
        {
          isSelected: false,
          url: photo3,
        },
      ],
      currentPhotoIndex: 0,
    }
  }

  selectGalleryPhoto(currentPhotoIndex) {
    const st = this.state;
    let photos = st.photos;

    photos[st.currentPhotoIndex].isSelected = false;
    photos[currentPhotoIndex].isSelected = true;
    this.setState({
      currentPhotoIndex: currentPhotoIndex,
      photos: photos,
    });
  }

  render() {
    const st = this.state;

    return (
      <div id="cozyboyGallery" className="cozyboyGallery">
        <div className="galleryTitle">PHØTØS</div>
        <Subtitle
          text="for you to look"
          color="white"
        />

        <div className="galleryContainer">
          <img className="galleryPhoto" src={st.photos[st.currentPhotoIndex].url} alt="something"/>

          <div className="galleryThumbnails">
            <img
              className="galleryThumbnail"
              src={photo0} alt="something"
              style={{
                opacity: st.photos[0].isSelected ? 1 : 0.3,
              }}
              onClick={this.selectGalleryPhoto.bind(this, 0)}
            />
            <img
              className="galleryThumbnail"
              src={photo1} alt="something"
              style={{
                opacity: st.photos[1].isSelected ? 1 : 0.3,
              }}
              onClick={this.selectGalleryPhoto.bind(this, 1)}
            />
            <img
              className="galleryThumbnail"
              src={photo2} alt="something"
              style={{
                opacity: st.photos[2].isSelected ? 1 : 0.3,
              }}
              onClick={this.selectGalleryPhoto.bind(this, 2)}
            />
            <img
              className="galleryThumbnail"
              src={photo3} alt="something"
              style={{
                opacity: st.photos[3].isSelected ? 1 : 0.3,
              }}
              onClick={this.selectGalleryPhoto.bind(this, 3)}
            />

          </div>
        </div>
        <div className="bottomBorderWhite"></div>
      </div>
    );
  }
}
