import React from 'react';
import '../App.css';

import Subtitle from "./Subtitle"

export default class CozyboyVideos extends React.Component {
  render() {
    return (
      <div id="cozyboyVideos" className="cozyboyVideos">
        <div className="videosTitle">VIDEØS</div>
        <Subtitle
          text="for you to see"
          color="white"
        />
        <iframe
          className="musicVideo1"
          src="https://www.youtube.com/embed/VrdbDbMgP7A"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <iframe
          className="musicVideo1"
          src="https://www.youtube.com/embed/wIEiZl3XvXQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <iframe
          className="musicVideo1"
          src="https://www.youtube.com/embed/VVK-pbDold8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <iframe
          className="musicVideo1"
          src="https://www.youtube.com/embed/gnvwRlSaX3Y"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>


        <div className="bottomBorderWhite"></div>
      </div>
    );
  }
}
